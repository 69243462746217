<template>
    <ion-title size="large" class="app-title">Creative Timer</ion-title>
</template>

<script>
import { IonTitle } from "@ionic/vue";

export default {
    name: "AppTitle",
    components: {
        IonTitle,
    }
}
</script>