<template>
  <form class="ion-padding" @submit.prevent="submitForm">
    <ion-list>
      <ion-item>
        <ion-label position="floating">Username / Email</ion-label>
        <ion-input type="text" v-model="enteredUsername" />
      </ion-item>
      <ion-item>
        <ion-label position="floating">Password</ion-label>
        <ion-input type="password" v-model="enteredPassword" />
      </ion-item>
    </ion-list>
    <ion-button expand="full" type="submit">Sign Up</ion-button>
  </form>
</template>

<script>
import { IonList, IonItem, IonLabel, IonInput, IonButton } from "@ionic/vue";

export default {
  name: "RegisterForm",
  emits: ["save-register", "save-error"],
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonInput,
    IonButton,
  },
  data() {
    return {
      enteredUsername: "",
      enteredPassword: "",
      errored: false
    };
  },
  methods: {
    submitForm() {
      const registerData = {
        userName: this.enteredUsername,
        password: this.enteredPassword,
      };
      if (registerData.userName == "") {
        this.errored = true;
      }
      else if (registerData.password == "") {
        this.errored = true;
      }
      else {
        this.errored = false;
      }
      this.$emit("save-error", this.errored);
      this.$emit("save-register", registerData);
    },
  },
};
</script>