<template>
    <ion-list>
        <ion-item lines="none">
            <ion-label>
                Don't have an account?
            </ion-label>
            <ion-button size="default" color="secondary" :router-link="`/register`">Sign Up</ion-button>
        </ion-item>
    </ion-list>
</template>

<script>
import { IonList, IonItem, IonLabel, IonButton } from "@ionic/vue";

export default {
    name: "RegisterMessage",
    components: {
        IonList,
        IonItem,
        IonLabel,
        IonButton
    }
}
</script>