<template>
  <base-layout page-title="Login">
    <app-title></app-title>
    <login-form @save-login="saveLogin" @save-error="saveError"></login-form>
    <validation-message v-if="errored" :errored="errored"></validation-message>
    <success-message v-if="isSubmitted" :errored="errored"></success-message>
    <register-message></register-message>
    {{GetPostLoginData}}
  </base-layout>
</template>

<script>
import LoginForm from "../components/authentication/login/LoginForm.vue";
import RegisterMessage from "../components/authentication/register/RegisterMessage.vue";
import AppTitle from "../components/title/AppTitle.vue";
import ValidationMessage from "../components/validation/ValidationMessage.vue";
import SuccessMessage from "../components/validation/SuccessMessage.vue";

export default {
  name: "LoginPage",
  data() {
    return {
      errored: false,
      isSubmitted: false
    };
  },
  components: {
      LoginForm,
      RegisterMessage,
      AppTitle,
      ValidationMessage,
      SuccessMessage
  },
  computed: {
    GetPostLoginData() {
      return this.$store.getters.postLoginData;
    }
  },
  methods: {
      saveLogin(loginData) {
        console.log(loginData);
        this.$store.dispatch('postLoginData', loginData)
      },
      saveError(errored) {
        this.errored = errored;
        if (this.errored == false) {
          this.isSubmitted = true;
        }
        else {
          this.isSubmitted = false;
        }
      }
  }
};
</script>