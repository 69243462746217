<template>
  <base-layout page-title="Sign Up">
    <app-title></app-title>
    <register-form @save-register="saveRegister" @save-error="saveError"></register-form>
    <validation-message v-if="errored" :errored="errored"></validation-message>
    <success-message v-if="isSubmitted" :errored="errored"></success-message>
  </base-layout>
</template>

<script>
import RegisterForm from "../components/authentication/register/RegisterForm.vue";
import AppTitle from "../components/title/AppTitle.vue";
import ValidationMessage from "../components/validation/ValidationMessage.vue";
import SuccessMessage from "../components/validation/SuccessMessage.vue";

export default {
  name: "RegisterPage",
  data() {
    return {
      errored: false,
      isSubmitted: false
    };
  },
  components: {
      RegisterForm,
      AppTitle,
      ValidationMessage,
      SuccessMessage
  },
  methods: {
      saveRegister(registerData) {
        console.log(registerData);
      },
      saveError(errored) {
        this.errored = errored;
        if (this.errored == false) {
          this.isSubmitted = true;
        }
        else {
          this.isSubmitted = false;
        }
      }
  }
};
</script>