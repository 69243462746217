<template>
  <ion-item lines="none">
    <ion-label v-if="!errored" color="success" class="validation">
      Successfull login!
    </ion-label>
  </ion-item>
</template>

<script>
import { IonLabel, IonItem } from "@ionic/vue";

export default {
  name: "SuccessMessage",
  components: {
    IonItem,
    IonLabel,
  },
  props: {
      errored: Boolean
  }
};
</script>