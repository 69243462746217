import { createStore } from 'vuex';
import authAxios from '../utils/axios/auth';

export default createStore({
  state: {
    loginData: null
  },
  mutations: {
    POST_LOGIN_DATA(state, loginData) {
      state.loginData = loginData;
    }
  },
  actions: {
    postLoginData({ commit }) {
      authAxios.post('/Login', {
        username: '123',
        password: '123'
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        commit('POST_LOGIN_DATA', error);
        console.log(error);
      });
    }
  },
  getters: {
    postLoginData: (state) => state.loginData
  },
  modules: {
  }
})
