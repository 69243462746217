<template>
  <base-layout page-title="Creative Timer">
  <template v-slot:actions-end>
    <ion-button router-link="/login">
     Sign in
    </ion-button>
  </template>
    <event-list></event-list>
  </base-layout>
</template>

<script>
import EventList from '../components/event/EventList.vue';
import { IonButton } from '@ionic/vue';


export default {
  name: "HomePage",
  components: {
      EventList,
      IonButton
  },
};
</script>