const events = [
    {
        id: 0,
        title: "Christmas",
        icon: require("../../images/event/christmas.png"),
        endDate: new Date("2021-12-25T00:00:00.000Z"),
        background: "card christmas-event"
    },
    {
        id: 1,
        title: "New Year",
        icon: require("../../images/event/newYear.png"),
        endDate: new Date("2022-01-01T00:00:00.000Z"),
        background: "card new-year-event"
    },
    {
        id: 2,
        title: "Easter",
        icon: require("../../images/event/easter.png"),
        endDate: new Date("2022-04-17T00:00:00.000Z"),
        background: "card easter-event"
    }
]

export default events;