<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
            <ion-back-button :default-href="pageDefaultBackLink"></ion-back-button>
        </ion-buttons>
        <ion-title>{{ pageTitle }}</ion-title>
        <ion-buttons slot="end">
          <slot name="actions-end"/>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <slot />
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonBackButton,
  IonButtons,
  IonList
} from "@ionic/vue";
export default {
  name: "BaseLayout",
  components: {
    IonPage: IonPage,
    IonHeader: IonHeader,
    IonTitle: IonTitle,
    IonContent: IonContent,
    IonToolbar: IonToolbar,
    IonBackButton: IonBackButton,
    IonButtons: IonButtons,
    IonList
  },
  props: {
      pageTitle: String,
      pageDefaultBackLink: String
  }
};
</script>