<template>
    <div>
        <event v-for="event in eventData" :key="event" :eventData="event" @onFinish="finish()"></event>
    </div>
</template>

<script>
import Event from './Event.vue';
import EventData from '../../data/event/Event';

export default {
    name: "EventList",
    components: {
        Event
    },
    data () {
        return {
            eventData: null
        }
    },
    mounted () {
        this.eventData = EventData;
    }
}
</script>