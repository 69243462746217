<template>
  <ion-item lines="none">
    <ion-label v-if="errored" color="danger" class="validation ion-text-wrap">
      Wrong credentials, try again!
    </ion-label>
  </ion-item>
</template>

<script>
import { IonLabel, IonItem } from "@ionic/vue";

export default {
  name: "ValidationMessage",
  components: {
    IonItem,
    IonLabel,
  },
  props: {
      errored: Boolean
  }
};
</script>