<template>
  <ion-card :class="eventData.background">
    <ion-card-header>
      <ion-card-subtitle class="card"><ion-label>⚪ {{ eventData.title }}</ion-label></ion-card-subtitle>
    </ion-card-header>

    <ion-card-content>
        <ion-grid>
            <ion-row>
                <ion-col size="3">
                    <ion-img class="event-image" :src="eventData.icon"></ion-img>
                </ion-col>
                <ion-col size="1"></ion-col>
                <ion-col style="text-align: center" size="2"><span class="clock" v-if="days">{{ days }}</span> <br /><span class="clock-title">DAYS</span></ion-col>
                <ion-col style="text-align: center" size="2"><span class="clock">{{ hours }}</span> <br /><span class="clock-title">HOURS</span></ion-col>
                <ion-col style="text-align: center" size="2"><span class="clock">{{ minutes }}</span> <br /><span class="clock-title">MINUTES</span></ion-col>
                <ion-col style="text-align: center" size="2"><span class="clock">{{ seconds }}</span> <br /><span class="clock-title">SECONDS</span></ion-col>
            </ion-row>
        </ion-grid>
    </ion-card-content>
  </ion-card>
</template>

<script>
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonGrid, IonRow, IonCol, IonImg, IonLabel } from '@ionic/vue';

export default {
  name: "Event",
  components: { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonGrid, IonRow, IonCol, IonImg, IonLabel },
  data () {
    return {
      now: Math.trunc((new Date()).getTime() / 1000),
      event: this.eventData.endDate,
      finish: false
    }
  },
  mounted() {
    const _self = this;
    window.setInterval(() => {
      this.now = Math.trunc((new Date()).getTime() / 1000)
      if (!this.finish && this.calculatedDate - this.now <= 0) {
        _self.finish = true
        _self.$emit('onFinish')
      }
    }, 1000)
  },
  computed: {
    secondCount () {
      return this.calculatedDate - this.now
    },
    calculatedDate () {
      return Math.trunc(Date.parse(this.event) / 1000)
    },
    seconds () {
      if (this.secondCount < 0) return 0
      return this.twoDigits(this.secondCount % 60);
    },
    minutes () {
      if (this.secondCount < 0) return 0
      return this.twoDigits(Math.trunc(this.secondCount / 60) % 60);
    },
    hours () {
      if (this.secondCount < 0) return 0
      return this.twoDigits(Math.trunc(this.secondCount / 60 / 60) % 24);
    },
    days () {
      if (this.secondCount < 0) return 0
      return this.twoDigits(Math.trunc(this.secondCount / 60 / 60 / 24));
    },
  },
  methods: {
    twoDigits (value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }
      return value.toString()
    }
  },
  props: {
    eventData: Object
  }
};
</script>